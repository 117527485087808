<template>
<div class="preloader">
    <div class="lds-ripple">
        <div class="lds-pos"></div>
        <div class="lds-pos"></div>
    </div>
</div>
</template>
<script>

export default {
    methods:{
        login(token){

            this.$store
            .dispatch("loginFromToken", token)
            .then(() => {
            })
            .catch((err) => {
                this.error = true;
            });
        }
                
    },
    mounted(){
        let access_token = this.$route.query.access_token;
        this.login(access_token);
    }
}
</script>